import React, { useState, useEffect } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import './ProjectsFeed.scss'
import { Image } from '../components/Image'
import GatsbyLink from '../components/GatsbyLink'
import { Link } from 'gatsby'
import Slider from 'react-slick'

const ProjectsFeedOutput = props => {
  const shuffle = a => {
    for (let i = a.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1))
      ;[a[i], a[j]] = [a[j], a[i]]
    }
    return a
  }

  const [dragging, setDragging] = useState(false)
  const [postList, setPostList] = useState([])

  const {
    optionalText,
    manuallySelectCaseStudies,
    customFilterLink,
    customButtonText,
    data,
  } = props

  const { caseStudies, location } = data

  useEffect(() => {
    const shuffleCaseStudies = shuffle(caseStudies.edges)
    setPostList(shuffleCaseStudies)
  }, [])

  var sliderSettings = {
    dots: false,
    arrows: false,
    speed: 500,
    slidesToShow: 3,
    // variableWidth: true,
    slidesToScroll: 3,
    beforeChange: () => setDragging(true),
    afterChange: () => setDragging(false),
    responsive: [
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
        },
      },
    ],
  }

  // hack because ACF post object doesn't work with graphql
  if (postList) {
    return (
      <section className="ProjectsFeed">
        <div className="background-helper"></div>
        <div className="wrapper">
          <span className="subtitle">Our Projects</span>
          {optionalText && (
            <div
              className="wysiwygcontent"
              dangerouslySetInnerHTML={{ __html: optionalText }}
            />
          )}
          <div className="content-overlay">
            <div className="flex-row">
              <Slider {...sliderSettings}>
                {manuallySelectCaseStudies && postList
                  ? postList.slice(0, 3).map((cs, index) => (
                      <div className="flex-item" key={index}>
                        <GatsbyLink
                          to={`/case-studies/${cs.node.slug}/`}
                          decode={false}
                          onClick={e => dragging && e.preventDefault()}
                        >
                          <div className="image-container">
                            <Image
                              className="featured-image"
                              src={cs.node.featuredImage}
                            />
                          </div>
                          <div className="text-container">
                            <span
                              className="title notranslate"
                              dangerouslySetInnerHTML={{
                                __html: cs.node.title,
                              }}
                            />
                            {cs.node.location[0] ? (
                              <span className="location notranslate">
                                {cs.node.location[0]
                                  ? location.edges.find(
                                      l =>
                                        l.node.wordpress_id ==
                                        cs.node.location[0]
                                    ).node.name
                                  : ''}
                              </span>
                            ) : (
                              ''
                            )}
                            <div className="read-more">
                              <span className="text">Read More</span>
                              <div className="overflow-hidden">
                                <div className="arrow"></div>
                              </div>
                            </div>
                          </div>
                        </GatsbyLink>
                      </div>
                    ))
                  : postList.slice(0, 3).map((cs, index) => (
                      <div className="flex-item" key={index}>
                        <GatsbyLink
                          to={`/case-studies/${cs.node.slug}/`}
                          decode={false}
                          onClick={e => dragging && e.preventDefault()}
                        >
                          <div className="image-container">
                            <Image
                              className="featured-image"
                              src={cs.node.featuredImage}
                            />
                          </div>
                          <div className="text-container">
                            <span
                              className="title notranslate"
                              dangerouslySetInnerHTML={{
                                __html: cs.node.title,
                              }}
                            />
                            {cs.node.location[0] ? (
                              <span className="location notranslate">
                                {cs.node.location[0]
                                  ? location.edges.find(
                                      l =>
                                        l.node.wordpress_id ==
                                        cs.node.location[0]
                                    ).node.name
                                  : ''}
                              </span>
                            ) : (
                              ''
                            )}
                            <div className="read-more">
                              <span className="text">Read More</span>
                              <div className="overflow-hidden">
                                <div className="arrow"></div>
                              </div>
                            </div>
                          </div>
                        </GatsbyLink>
                      </div>
                    ))}
              </Slider>
            </div>
            <div className="view-all-button center">
              <Link
                to={
                  customFilterLink
                    ? `/case-studies/filter?${customFilterLink}`
                    : `/case-studies/`
                }
                activeClassName="active"
                className="button"
                aria-current="true"
              >
                <span>
                  {customButtonText ? customButtonText : 'View All Projects'}
                </span>
              </Link>
            </div>
          </div>
        </div>
      </section>
    )
  }

  return (
    <section className="ProjectsFeed">
      <div className="background-helper"></div>
      <div className="wrapper">
        <span>No case studies found</span>
      </div>
    </section>
  )
}

export const ProjectsFeed = props => {
  return (
    <StaticQuery
      query={graphql`
        query {
          caseStudies: allWordpressWpCaseStudies(
            sort: { fields: menu_order, order: ASC }
          ) {
            edges {
              node {
                slug
                title
                location
                sector
                wordpress_id
                featuredImage: featured_image_url {
                  source_url
                }
              }
            }
          }
          location: allWordpressWpLocation(filter: { count: { gt: 0 } }) {
            edges {
              node {
                name
                slug
                wordpress_id
                wordpress_parent
              }
            }
          }
          sector: allWordpressWpSector(filter: { count: { gt: 0 } }) {
            edges {
              node {
                name
                slug
                wordpress_id
                wordpress_parent
              }
            }
          }
        }
      `}
      render={data => <ProjectsFeedOutput {...props} data={data} />}
    />
  )
}
